.swiper-wrapper {
  min-height: 230px;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  width: 100%;
  
  
}
.swiper-pagination-bullet {
  background-color: white;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: #2d31fa !important;
}
